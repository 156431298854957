import { admin2 } from './instance'

export const GetTrackingLink = async ({
  shopId,
  name,
  start,
  limit,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/trackingLink`,
    params: {
      name,
      start,
      limit,
    },
  })
}

export const GetTrackingLinkCount = async ({
  shopId,
  name,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/trackingLink/count`,
    params: {
      name,
    },
  })
}

export const FindTrackingLink = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/trackingLink/${id}`,
  })
}

export const FindViewCount = async ({
  shopId,
  ids,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/trackingLink/viewCount`,
    params: {
      ids,
    },
  })
}

export const CreateTrackingLink = async ({
  shopId,
  name,
  targetLink,
  start,
  end,
  registerMTags,
  actionMTags,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/trackingLink`,
    data: {
      name,
      targetLink,
      start,
      end,
      registerMTags,
      actionMTags,
    },
  })
}

export const UpdateTrackingLink = async ({
  shopId,
  id,
  name,
  start,
  end,
  registerMTags,
  actionMTags,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/trackingLink/${id}`,
    data: {
      name,
      start,
      end,
      registerMTags,
      actionMTags,
    },
  })
}

export const DeleteTrackingLink = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/trackingLink/${id}`,
  })
}

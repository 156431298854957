<template>
  <div>
    <PageTitle
      :title="displayText.pageTitle"
      :btn="displayText.btn.create"
      :btnLoading="createLiffLoading"
      @btnClick="createTrackingLink"
    />
    <FiltersContainer>
      <BaseElInput
        v-model="nameSearch"
        testName="search_name"
        clearable
        :placeholder="displayText.search.placeholder"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
    </FiltersContainer>
    <BaseTable v-loading="loading.table" :data="tableData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn :label="displayText.name.label" prop="name" align="center" />
      <BaseElTableColumn :label="displayText.shortLink.label" prop="shortLink" align="center">
        <template slot-scope="scope">
          <div class="flex items-center" style="gap: 10px">
            <p class="w-full">{{ scope.row.shortLink }}</p>
            <span
              class="material-icons text-primary-100 cursor-pointer"
              style="width: 24px; transform: scaleX(-1)"
              @click="copyLink(scope.row.shortLink)"
            >
              content_copy_outlined
            </span>
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn :label="displayText.tag.label" align="center">
        <template slot-scope="scope">
          <div class="flex flex-wrap" style="gap: 8px">
            <Tag v-for="tag in tagList(scope.row)" :key="tag" type="action">{{ tag }}</Tag>
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn :label="displayText.start.label" prop="start" width="170px" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.start) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn :label="displayText.end.label" prop="end" width="170px" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.end) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn :label="displayText.status.label" prop="status" align="center" width="100px">
        <template slot-scope="scope">
          <Tag :type="get(linkStatus(scope.row), 'type')">
            {{ get(linkStatus(scope.row), 'label') }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn :label="displayText.viewCount.label" prop="viewCount" align="center" width="100px" />
      <BaseElTableColumn :label="displayText.action.label" prop="status" fixed="right" width="150px" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            :hideCopy="false"
            @copy="copyTrackingLink(scope.row)"
            @edit="editTrackingLink(scope.row)"
            @delete="hadnleDelete(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />

    <DeleteDialog
      v-if="dialog.delete"
      :title="displayText.delete.title"
      :content="displayText.delete.content"
      width="40%"
      @close="dialog.delete = false"
      @delete="deleteTrackingLink"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, onActivated, ref, set, computed } from 'vue'
import { GetTrackingLink, GetTrackingLinkCount, DeleteTrackingLink, FindViewCount } from '@/api/trackingLink'
import { GetLiff, CreateLiff } from '@/api/shop'
import { find, get, map } from 'lodash'
import dayjs from '@/lib/dayjs'
import copy from 'clipboard-copy'
import { useTable } from '@/use/table'
import { useRouter } from 'vue-router/composables'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'TrackingLinkManagement',
  setup () {
    const {
      tableData,
      tableOptions,
      dateFormat,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
      dialog,
    } = useTable()
    const router = useRouter()
    const nameSearch = ref('')
    const needCreateLiff = ref(false)
    const createLiffLoading = ref(false)
    const target = ref(null)
    const displayText = computed(() => {
      const data = {
        pageTitle: '追蹤連結列表',
        btn: {
          create: '新增',
        },
        search: {
          placeholder: '輸入連結名稱',
        },
        name: {
          label: '連結名稱',
        },
        shortLink: {
          label: '追蹤連結',
        },
        tag: {
          label: '標籤',
        },
        start: {
          label: '開始時間',
        },
        end: {
          label: '結束時間',
        },
        status: {
          label: '連結狀態',
        },
        viewCount: {
          label: '點擊次數',
        },
        action: {
          label: '操作',
        },
        delete: {
          title: '提醒',
          content: '刪除後將無法復原，確定要刪除？',
        },
      }
      return data
    })
    const checkLiff = async () => {
      const res = await GetLiff({ shopId: shopId.value })
      const exist = find(res, { name: 'trackingLink' })
      if (!exist) needCreateLiff.value = true
    }
    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      const ids = map(tableData.value, 'id')
      if (ids.length) await findViewCount(ids)
      loading.table = false
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: (nameSearch.value === '') ? undefined : nameSearch.value,
      }
      await Promise.allSettled([
        fetchData(GetTrackingLink, payload),
        fetchDataCount(GetTrackingLinkCount, payload),
      ])
    }
    const createTrackingLink = async () => {
      createLiffLoading.value = true
      if (needCreateLiff.value) {
        if (!await createLiff()) {
          createLiffLoading.value = false
          return
        }
      }
      router.push({ name: 'EditTrackingLink' })
      createLiffLoading.value = false
    }
    const findViewCount = async (ids) => {
      const [res, err] = await FindViewCount({
        shopId: shopId.value,
        ids: ids.join(','),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      res.forEach(i => {
        const target = find(tableData.value, { id: i.id })
        set(target, 'viewCount', i.viewCount)
      })
    }
    const copyLink = (link) => {
      copy(link)
      window.$message.success('複製連結成功！')
    }
    const tagList = (row) => {
      const register = row.RegisterMTags
      const action = row.ActionMTags
      let count = 0
      const list = []
      if (register.length) {
        list.push(get(register, '[0].name'))
        if (register.length - 1) count += register.length - 1
      }
      if (action.length) {
        list.push(get(action, '[0].name'))
        if (action.length - 1) count += action.length - 1
      }
      if (count) list.push(`+${count}`)
      return list
    }
    const editTrackingLink = async (row) => {
      router.push({
        name: 'EditTrackingLink',
        params: {
          id: row.id,
        },
      })
    }
    const copyTrackingLink = async (row) => {
      router.push({
        name: 'EditTrackingLink',
        params: {
          id: row.id,
        },
        query: {
          copy: true,
        },
      })
    }
    const hadnleDelete = (row) => {
      target.value = row
      dialog.delete = true
    }
    const deleteTrackingLink = async () => {
      const [, err] = await DeleteTrackingLink({
        shopId: shopId.value,
        id: get(target.value, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.deleteSuccess)
      dialog.delete = false
      await refresh()
    }
    const createLiff = async () => {
      try {
        await CreateLiff({
          shopId: shopId.value,
          name: 'trackingLink',
        })
      } catch (err) {
        window.$message.error(err)
        return false
      }
      return true
    }
    const linkStatus = (row) => {
      const start = dayjs(row.start)
      const end = dayjs(row.end)
      const now = dayjs()
      if (now.isAfter(end)) {
        return {
          type: 'info',
          label: '已結束',
        }
      }
      if (now.isAfter(start) && now.isBefore(end)) {
        return {
          type: 'warning',
          label: '進行中',
        }
      }
      if (now.isBefore(start)) {
        return {
          type: 'action',
          label: '已排程',
        }
      }
    }
    onMounted(async () => {
      checkLiff()
      await refresh()
    })
    onActivated(async () => {
      await refresh()
    })
    return {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      createTrackingLink,
      nameSearch,
      refresh,
      target,
      dialog,
      get,
      copyLink,
      tagList,
      editTrackingLink,
      copyTrackingLink,
      hadnleDelete,
      deleteTrackingLink,
      linkStatus,
      createLiffLoading,
      dateFormat,
      displayText,
    }
  },
})
</script>

<style lang="scss" scoped>

</style>
